// src/components/GiftCardCancel.tsx

import React from 'react';
import { Link } from 'react-router-dom';

const GiftCardCancel: React.FC = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-cream p-4">
      <div className="max-w-md bg-white p-8 rounded shadow">
        <h1 className="text-2xl font-recia text-maroon mb-4">Payment Canceled</h1>
        <p className="mb-4 text-darkBrown">Your gift card purchase was canceled. If this was a mistake, feel free to try again.</p>
        <Link to="/">
          <button className="px-4 py-2 bg-maroon text-cream rounded hover:bg-olive">
            Home
          </button>
        </Link>
      </div>
    </div>
  );
};

export default GiftCardCancel;