// src/stores/GiftCardStore.ts

import { makeAutoObservable, runInAction } from 'mobx';
import API from '../api/client'; // your configured axios instance
import { toast } from 'react-toastify';

class GiftCardStore {
  amount: number = 1;
  email: string = '';
  delivery_method: 'ship' | 'pickup' = 'pickup';
  isSubmitting: boolean = false;
  error: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setAmount(value: number) {
    this.amount = value;
  }

  setEmail(value: string) {
    this.email = value;
  }

  setDeliveryMethod(value: 'ship' | 'pickup'){
    this.delivery_method = value;
  }

  async createSession() {
    this.isSubmitting = true;
    this.error = null;

    // Basic validation
    if (this.amount <= 0) {
      runInAction(() => {
        this.error = 'Please enter a valid amount.';
        this.isSubmitting = false;
      });
      return;
    }

    const emailRegex = /\S+@\S+\.\S+/;
    if (!this.email || !emailRegex.test(this.email)) {
      runInAction(() => {
        this.error = 'Please enter a valid email.';
        this.isSubmitting = false;
      });
      return;
    }

    try {
      const response = await API.post('/api/create-giftcard-session/', {
        amount: this.amount,
        email: this.email,
        delivery_method: this.delivery_method
      });

      const { sessionId } = response.data;

      // Stripe redirect
      const stripe = (window as any).Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);
      if (stripe) {
        const result = await stripe.redirectToCheckout({ sessionId });
        if (result.error) {
          runInAction(() => {
            this.error = result.error.message || 'An error occurred during checkout.';
          });
          toast.error(this.error);
        } else {
          // Successful redirect - no further action needed here
        }
      }
    } catch (err: any) {
      console.error('Error creating session:', err);
      runInAction(() => {
        this.error = 'Failed to create checkout session. Please try again.';
      });
      toast.error(this.error);
    } finally {
      runInAction(() => {
        this.isSubmitting = false;
      });
    }
  }

  reset() {
    this.amount = 1;
    this.email = '';
    this.isSubmitting = false;
    this.error = null;
  }
}

const giftCardStore = new GiftCardStore();
export default giftCardStore;