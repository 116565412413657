import React from 'react';
import grapesImage from '../../assets/images/Grapes.svg';

// Now weeklyEvents just returns Event type objects
// Event: { id: number; title: string; description: string; date: string; pdf: string | null; }

import { Event } from '../../stores/EventsStore';

export const weeklyEvents: Event[] = [
  {
    id: 1,
    title: 'INDUSTRY NIGHT',
    description: '20% off wine bottles and complimentary pasta.',
    date: 'Tuesdays 5-7 P.M.',
    pdf: null,
  },
  {
    id: 2,
    title: 'LOCAL NIGHT',
    description: 'Flight 5 for $25 and a Dinner Special.',
    date: 'Wednesdays 5-7 P.M.',
    pdf: null,
  },
  {
    id: 3,
    title: 'WINE TASTING',
    description: 'Wine tasting with two pasta options.',
    date: 'Thursdays 5-7 P.M.',
    pdf: null,
  },
  // {
  //   id: 4,
  //   title: 'BOTTOMLESS MIMOSAS',
  //   description: '$15 bottomless mimosas.',
  //   date: 'Sundays 1-3 P.M.',
  //   pdf: null,
  // },
];

// This file defines the static mappings for weekly events extra display info.

// Define a type for the display info
interface WeeklyEventDisplayInfo {
  priceOrDiscount: string;
  backgroundColor: string;
  timeBoxColor: string;
  timeBoxTextColor: string;
  imageUrl: string;
}

// Use Record<number, WeeklyEventDisplayInfo>
export const weeklyEventDisplayInfo: Record<number, WeeklyEventDisplayInfo> = {
  1: {
    priceOrDiscount: '20% OFF',
    backgroundColor: 'bg-olive',
    timeBoxColor: 'bg-cream',
    timeBoxTextColor: 'text-olive',
    imageUrl: 'https://enoteca-bucket.s3.amazonaws.com/Grab_A_Bottle_Best-topaz.webp'
  },
  2: {
    priceOrDiscount: '$25',
    backgroundColor: 'bg-maroon',
    timeBoxColor: 'bg-olive',
    timeBoxTextColor: 'text-cream',
    imageUrl: 'https://enoteca-bucket.s3.amazonaws.com/Ciao-topaz.webp'
  },
  3: {
    priceOrDiscount: '$15',
    backgroundColor: 'bg-gold',
    timeBoxColor: 'bg-maroon',
    timeBoxTextColor: 'text-cream',
    imageUrl: 'https://enoteca-bucket.s3.amazonaws.com/Pouring-Wine.webp'
  },
  4: {
    priceOrDiscount: '$15',
    backgroundColor: 'bg-olive',
    timeBoxColor: 'bg-cream',
    timeBoxTextColor: 'text-olive',
    imageUrl: 'https://enoteca-bucket.s3.amazonaws.com/Mimosas.webp'
  }
};

const WeeklyEvents: React.FC = () => {


  return (
    <section className="w-screen bg-cream">
      {/* Section Title */}
      <div className="w-full flex flex-col sm:flex-row sm:justify-center items-center text-maroon pt-8 tracking-widest mb-8">
        <div className="flex items-center sm:mr-4 mb-4 sm:mb-0">
          <h2 className="text-4xl lg:text-5xl font-recia uppercase mr-2">WEEKLY</h2>
          <h2 className="text-4xl lg:text-5xl font-satoshi uppercase">EVENTS</h2>
        </div>
        <img src={grapesImage} alt="Grapes" className="w-32 h-32" />
      </div>

      {weeklyEvents.map((event, idx) => {
        const textIsOnLeft = idx % 2 === 0;
        const info = weeklyEventDisplayInfo[event.id];

        // On mobile: stack vertically (text top, image bottom)
        // On desktop: place text on left or right depending on idx
        const containerClasses = `flex flex-col md:flex-row items-stretch ${textIsOnLeft ? '' : 'md:flex-row-reverse'} w-full`;

        const textClasses = `${info.backgroundColor} text-cream md:w-1/4 w-full flex flex-col justify-center items-center text-center relative p-6 md:p-8`;
        const imageClasses = `relative md:w-3/4 w-full`;

        // Split title for font differences
        const titleWords = event.title.split(' ');
        const firstWord = titleWords[0] || '';
        const restWords = titleWords.slice(1).join(' ');

        // Title color logic
        const titleColor = event.title === 'LOCAL NIGHT' ? 'text-gold' : 'text-cream';

        // Price styling
        let priceClasses = "text-5xl font-bold mb-4 font-satoshi uppercase";
        if ((info.priceOrDiscount || event.id === 2) && event.id !== 3) {
          priceClasses += " text-gold";
        }

        // Overlay position
        const overlayPosition = textIsOnLeft ? "left-0" : "right-0";

        return (
          <div key={event.id} className={containerClasses}>
            {/* Solid Color + Text Section */}
            <div className={textClasses}>
              {/* Date/Time Box */}
              <div className={`inline-block px-4 py-2 ${info.timeBoxColor} ${info.timeBoxTextColor} font-bold rounded mb-4 uppercase`}>
                {event.date}
              </div>

              <h3 className={`text-4xl mb-4 uppercase ${titleColor}`}>
                <span className="font-satoshi">{firstWord}</span>
                {restWords && ' '}
                {restWords && <span className="font-recia">{restWords}</span>}
              </h3>

              {info.priceOrDiscount && (
                <p className={priceClasses}>{info.priceOrDiscount}</p>
              )}
              <p className="text-lg font-satoshi">{event.description}</p>
            </div>

            {/* Image Section */}
            <div className={imageClasses}>
              <img
                src={info.imageUrl}
                alt={event.title}
                className="w-full h-auto object-cover"
              />
              {/* Partial Overlay (1/4 of the image) */}
              <div className={`absolute inset-y-0 ${overlayPosition} w-1/4 ${info.backgroundColor} opacity-50 pointer-events-none`}></div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default WeeklyEvents;