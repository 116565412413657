// src/components/GiftCardSuccess.tsx

import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const GiftCardSuccess: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sessionId = params.get('session_id');

  return (
    <div className="min-h-screen flex items-center justify-center bg-cream p-4">
      <div className="max-w-md bg-white p-8 rounded shadow text-darkBrown">
        <h1 className="text-2xl font-recia text-maroon mb-4">Thank You!</h1>
        <p className="mb-4">Your gift card purchase was successful.</p>
        <p className="mb-4">
          A confirmation email has been sent to your recipient. If you chose shipping, the gift card will be sent out the following business day. If you chose pickup, please bring the gift card code when you come to pick it up.
        </p>
        <Link to="/">
          <button className="px-4 py-2 bg-maroon text-cream rounded hover:bg-olive">
            Return to Home
          </button>
        </Link>
      </div>
    </div>
  );
};

export default GiftCardSuccess;