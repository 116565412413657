// src/components/CollectibleWines/index.tsx

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import collectibleWineStore from '../../stores/CollectibleWineStore';
import CollectibleWineItem from './CollectibleWineItem';
import LeafDivider from '../../assets/images/LeafDivider.svg';
import CollectibleStain1 from '../../assets/images/Collectible-Stain-1.svg';
import CollectibleStain2 from '../../assets/images/Collectible-Stain-2.svg';
import GiftCardModal from '../GiftCards/GiftCardModal';

const CollectibleWine: React.FC = observer(() => {
  useEffect(() => {
    collectibleWineStore.fetchWines();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const { wines, isLoading, error } = collectibleWineStore;

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading wines.</div>;

  return (
    <div>
      {/* Hero Section */}
      <div className="relative">
        <img
          src="https://enoteca-bucket.s3.amazonaws.com/Bottles_On_Bottles_On_Bottles-topaz.webp"
          alt="Collectible Wines"
          className="w-full h-screen object-cover"
        />
        <div
          className="absolute right-0 pr-6 md:pr-12 flex items-center"
          style={{ top: '15vh', bottom: '0' }}
        >
          <div className="text-right max-w-md">
            <h1 className="text-5xl md:text-7xl font-satoshi text-cream leading-tight">
              Collectible
            </h1>
            <h1 className="text-5xl md:text-7xl font-recia text-cream leading-tight">
              Wines
            </h1>
            <p className="mt-4 text-base md:text-lg text-cream">
              Experience the energy and passion in our exclusive selection of collectible wines.
              Each bottle tells a story, curated for the discerning collector.
            </p>
          </div>
        </div>

        {/* Separator Overlay */}
          <div className="absolute translate-y-[-33%] w-full z-0">
            <img
              src={LeafDivider}
              alt="Leaf Divider"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>

        {/* Gift Cards Section */}
        <div className="relative bg-cream py-24 px-4 sm:px-6 md:px-8 lg:px-16 text-center z-10">
          <h2 className="text-3xl md:text-4xl font-recia text-maroon leading-tight">
            Give the Gift of Enoteca
          </h2>
          <p className="mt-4 text-darkBrown font-satoshi text-lg md:text-xl max-w-xl mx-auto">
            Surprise a friend or loved one with a gift card to Via Maestra Enoteca. Perfect for any occasion—birthdays, anniversaries, or just a thoughtful gesture.
            Gift cards are available for pickup or shipping, and take one business day to process.
          </p>
          <div className="mt-8">
            <button
              onClick={openModal}
              className="px-6 py-3 bg-maroon text-cream font-satoshi rounded-full hover:bg-olive transition-colors duration-300"
            >
              Purchase Gift Card
            </button>
          </div>
        </div>

      {/* Wines List */}
      <div className="bg-cream relative z-20">
        {/* Add stains only once */}
        <>
          {/* Upper Left Stain */}
          <img
            src={CollectibleStain1}
            alt="Wine Stain 1"
            className="absolute top-0 left-0 w-64 h-64 object-contain"
            style={{ transform: 'translate(-30%, -30%)' }}
          />
          {/* Bottom Right Stain */}
          <img
            src={CollectibleStain2}
            alt="Wine Stain 2"
            className="absolute top-40 right-0 w-64 h-64 object-contain"
            style={{ transform: 'translate(30%, 30%)' }}
          />
        </>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {wines.map((wine) => {
            const selectedVintage =
              wine.vintages.find((v) => v.id === wine.selectedVintageId) || wine.vintages[0];

            return (
              <div key={wine.baseName} className="relative flex flex-col">
                {/* Image Section */}
                <div className="flex justify-center pt-48 pb-8" style={{ position: 'relative' }}>
                  <img
                    src={selectedVintage.image}
                    alt={wine.baseName}
                    className="relative w-48 h-64 object-cover rounded-md shadow-lg bottom-[-3.5rem] z-20"
                  />
                </div>

                {/* Details Section */}
                <div className="relative flex-1 flex flex-col">
                  {/* Gold Overlay */}
                  <div className="absolute inset-0 bg-gold opacity-30 pointer-events-none z-10"></div>

                  {/* Content */}
                  <div className="relative pt-24 pb-8 max-w-sm mx-auto flex flex-col h-full">
                    <CollectibleWineItem wine={wine} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {isModalOpen && <GiftCardModal onClose={closeModal} />}
    </div>
  );
});

export default CollectibleWine;