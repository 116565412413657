// src/components/GiftCardModal.tsx
import React from 'react';
import { observer } from 'mobx-react-lite';
import giftCardStore from '../../stores/GiftCardStore';

interface GiftCardModalProps {
  onClose: () => void;
}

const GiftCardModal: React.FC<GiftCardModalProps> = observer(({ onClose }) => {
  const { amount, email, isSubmitting, error, delivery_method } = giftCardStore;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await giftCardStore.createSession();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="bg-white w-full max-w-md p-6 rounded shadow-lg">
        <h2 className="text-xl font-bold mb-4 text-center text-maroon">Purchase Gift Card</h2>
        <form onSubmit={handleSubmit}>
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Amount (USD):</label>
            <input
              type="number"
              value={amount}
              onChange={(e) => giftCardStore.setAmount(parseInt(e.target.value, 10) || 0)}
              required
              min={1}
              className="w-full border border-gray-300 p-2 rounded text-darkBrown"
              placeholder="e.g. 50"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Recipient Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => giftCardStore.setEmail(e.target.value)}
              required
              className="w-full border border-gray-300 p-2 rounded text-darkBrown"
              placeholder="recipient@example.com"
            />
          </div>
          <div className="mb-4 text-gray-700 space-x-4">
            <label>
              <input className="mr-2" type="radio" value="pickup" checked={delivery_method==='pickup'} onChange={() => giftCardStore.setDeliveryMethod('pickup')} />
              Pickup
            </label>
            <label>
              <input className="mr-2" type="radio" value="ship" checked={delivery_method==='ship'} onChange={() => giftCardStore.setDeliveryMethod('ship')} />
              Ship
            </label>
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={() => {
                giftCardStore.reset();
                onClose();
              }}
              disabled={isSubmitting}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 bg-maroon text-cream rounded hover:bg-olive"
            >
              {isSubmitting ? 'Processing...' : 'Proceed to Checkout'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
});

export default GiftCardModal;